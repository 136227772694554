const data = {

    application : {

        status : 'pending',
        submitted : false,
        uid : '',
        grade : 0,
        gradedBy : []
    }

}


export {data}